.exam-schedules-grid-h {
    position: relative;
    height: calc(100vh - 280px);

    .k-grid {
        tr.completed {
            color: #155724;
            background-color: #d4edda;

            &.k-alt {
                background-color: #d4edda;
            }

            &:hover {
                background-color: #dcf5e3;
            }
        }
    }
}
