.examinations-defects-grid-h {
    position: relative;

    .k-grid-table {
        min-height: 40px;
    }

    .k-grid .k-column-title {
        white-space: normal;
    }

    .k-grid {
        th {
            color: #212529;
            background-color: #f4f4f4;
        }
        tr.closed {
            color: #155724;
            background-color: #d4edda;

            &.k-alt {
                background-color: #d4edda;
            }

            &:hover {
                background-color: #dcf5e3;
            }
        }
        tr {
            color: #856404;
            background-color: #fff3cd;
            &.k-alt {
                background-color: #fff3cd;
            }

            &:hover {
                background-color: #fff3cd;
            }
        }
    }
    .k-master-row .k-grid-content-sticky.closed {
        color: #155724;
        background-color: #d4edda;

        &.k-alt {
            background-color: #d4edda;
        }

        &:hover {
            background-color: #dcf5e3;
        }
    }
    .k-master-row .k-grid-content-sticky {
        color: #856404;
        background-color: #fff3cd;
        &.k-alt {
            background-color: #fff3cd;
        }

        &:hover {
            background-color: #fff3cd;
        }
    }
}

.activity-header.complete {
    color: #155724;
    background-color: #d4edda;
}
.activity-header.open {
    color: #856404; 
    background-color: #fff3cd;
}
.activity-header.na {
    color: #1b1e21;
    background-color: #d6d8d9;
}
