$highlight-color-hover: #eff3f5;
$highlight-color-focus: #e1e3eb;
$highlight-color-active: #dee3eb;

// Inputs

$border-color-error: #dc3545;

// Tables & table-looking components with rows (with header and alternating rows colors)

$rows-header-bg: #e9ecef;
$row-bg: white;
$row-odd-bg: #f8f9fa;
