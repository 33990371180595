.asset-grid-h {
    position: relative;
    height: calc(100vh - 300px);
}

#asset-templates-1 {
    display: none;
    visibility: hidden;
}

#asset-templates-cefa {
    visibility: visible;
}
.assets-templates-filters {
    display: flex;
    align-items: center;
    justify-content: right;

    label {
        margin: 0;
    }

    .assets-templates-multiselect {
        max-width: 320px;
        display: block;
        margin-left: 25px;

        > div:first-child {
            height: 38px;
            overflow-y: scroll;

            > span:first-child {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}
