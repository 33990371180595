.process-activity-column {
    height: 100%;
    min-width: 400px;
}

.complete-full-wrapper {
    display: flex;
}

.complete-full-wrapper > * {
    flex: 1 1 0;
}

.add-assets-grid{
    max-height: 60vh;
    width: 400px;
}