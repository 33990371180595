.add-assets-modal {
    .assets-form {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .action-buttons {
        & > div {
            margin: 20px 0 0;
            padding: 0;
            border: none;
        }
    }
}
