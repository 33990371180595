@use "../../../styles/functions" as *;

$font-colour: #b8bbc2;

.menu-icon {
    margin-right: 12px;
    @if isSbim()  {
        color: #b8bbc2;
    }
}

.menu-icon-small {
    margin-right: 12px;
    margin-left: 2px;
}

.nav-text-small {
    font-size: 14px;
    @if isSbim() {
        text-transform: uppercase;
    }
}

@if isSbim() {
    #side-menu-background {
        background-color: #35373d;
    }
    .navbar-font-override {
        color: $font-colour;
        text-transform: uppercase;
    }
    .image-placeholder {
        height: auto;
        padding: 0;
    }
    .menu-image {
        padding: 0;
        display: block;
        max-width:100%;
    }
    .sub-menu-button-colour {
        background-color: $font-colour;
    }
    .button-override {
        padding:1rem
    }
}
