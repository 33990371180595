@import "../../../styles/variables";
    
.item-highlight{
    &:hover{
        background-color: $highlight-color-hover;
    }
    &:focus{
        background-color: $highlight-color-focus;
    }
    &:active{
        background-color: $highlight-color-active;
    }
}

.item-row-title{
    font-size: 15px;
    margin-bottom: 0;
}

.item-row-description{
    font-size: 12px;
}