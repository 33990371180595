@use "../../../styles/functions" as *;

.btn-icon,
.btn.btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 48px;
    @if isSbim() { 
        color: #b8bbc2;
        padding: 1rem;
    }
    background: transparent;
    border: 0;
}

.btn-icon:hover > svg {
    color: var(--primary);
}

.btn.btn-icon:focus {
    box-shadow: none;
}
