@import "../../../../../styles/variables";

.review-summary {
    .summary-row {
        display: flex;
        padding: 5px;

        &:nth-child(odd) {
            background: $row-odd-bg;
        }

        & > .summary-label {
            width: 100px;
            margin-right: 20px;
        }

        &.summary-header {
            background-color: $rows-header-bg;
        }
    }
}
