@mixin reset-bt {
    background: none !important;
    background-color: transparent !important;
    color: black !important;
    outline: none !important;
    box-shadow: none !important;
}

.dropdown {
    &-main {
        position: absolute;
        top: -5px;
        right: -20px;
    }

    &-dots {
        background: none;
        border: none;
        outline: none;
        color: black;
        position: absolute;
        right: 15px;
        top: -10px;

        &.btn-primary {
            @include reset-bt();
        }

        &.show {
            @include reset-bt();
        }

        &:after {
            content: "\2807";
            border: none;
            font-size: 1rem;
        }
    }
}
