@import "../../../styles/variables";

.dynamic-form-grid {
    &.is-invalid {
        td {
            border-color: var(--red);
            border-width: 1px;
        }
    }
    tr.new-row  {
            background-color: #e8fce8;             
    }       
}
