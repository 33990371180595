@use "../../../styles/functions" as *;

a.navbar-brand {
    white-space: normal;
    word-break: break-all;
}

$font-colour: #b8bbc2;
$nav-button-color: black;
@if isSbim()  {
    $nav-button-color: $font-colour;
}

.nav-button {
    color: $nav-button-color;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

@if isSbim() {
    #nav-header-menu {
        background-color:#39485f;
        border-color: #30470E
    }
    .nav-item {
        color: $font-colour;
    }
    .text-items {
        color: $font-colour;
    }
}


.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
