.comment-holder {
    max-width: 100%;
    overflow-x: auto;
}
.comment-holder img {
    max-width: 50vw;
}
.comment-holder table,
.comment-holder th,
.comment-holder td {
    border: 1px solid #ddd;
}
.comment-holder table td p {
    margin: 5px;
}
