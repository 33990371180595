.defect-table:not(:first-child) {
    margin-top: 30px;
}

.defect-description {
    display: flex;
    justify-content: space-between;
}

.defect-title {
    border: none !important;
    font-size: 20px;
}